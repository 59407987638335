import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap'
import logo from '../../content/assets/logo.svg'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Container>
          <Row>
            <Col className='text-center mt-5' sm={12}>
              <SEO title="404: Not Found" />
              <img src={logo} width='200px' />
              <h1 className='mt-5'>Error 404: Not found</h1>
              <h2>There is no page here.</h2>
              <p>You have hit a route that doesn&#39;t exist... the sadness.</p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
